<template>
	<div id="getWidth">
		<form
			@submit.prevent="addressInfoSubmit"
			@keydown="clearError($event.target.name);"
			@change="clearError($event.target.name);">
			<b-row v-if="!hideNameFields">
				<b-col
					cols="12"
					md="6">
					<input-text
						:id="'first_name'"
						:required="true"
						:label="translate('first_name')"
						:setter-value="form.first_name"
						:errors="errors[`${prefixErrors}first_name`]"
						type="text"
						autocomplete="given-name"
						@dataChanged="form.first_name = $event" />
				</b-col>
				<b-col
					cols="12"
					md="6">
					<input-text
						:id="'last_name'"
						:required="true"
						:label="translate('last_name')"
						:setter-value="form.last_name"
						:errors="errors[`${prefixErrors}last_name`]"
						type="text"
						autocomplete="family-name"
						@dataChanged="form.last_name = $event" />
				</b-col>
			</b-row>
			<b-row>
				<b-col cols="12">
					<input-text
						:id="'address'"
						:required="true"
						:disabled="form.is_pickup_address"
						:label="translate('street')"
						:setter-value="form.address"
						:errors="errors[`${prefixErrors}address`]"
						:max="42"
						type="text"
						autocomplete="address-line1"
						@dataChanged="form.address = $event" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<input-text
						:id="'external_number'"
						:disabled="form.is_pickup_address"
						:label="translate('external_number')"
						:setter-value="externalNumber"
						:max="5"
						:errors="errors[`${prefixErrors}address2`]"
						required
						type="text"
						autocomplete="address-line2"
						@dataChanged="externalNumber = $event" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<input-text
						:id="'interior_number'"
						:disabled="form.is_pickup_address"
						:label="translate('interior_number')"
						:setter-value="internalNumber"
						:max="5"
						type="text"
						autocomplete="address-line2"
						@dataChanged="internalNumber = $event" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<input-text
						:id="'postcode'"
						:required="true"
						:disabled="form.is_pickup_address"
						:label="translate('postcode')"
						:setter-value="form.postcode"
						:errors="errors[`${prefixErrors}postcode`]"
						:max="5"
						type="text"
						pattern="\d*"
						autocomplete="postal-code"
						@dataChanged="handlePostCodeChange($event)" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<div
						class="form-group">
						<label
							for="suburb_name"
							class="label">
							{{ translate('suburb_name') }}
							<span class="text-danger">*</span>
						</label>
						<select
							id="suburb_name"
							v-model="suburbIndex"
							:disabled="form.is_pickup_address"
							:required="true"
							:class="hasError('address_3') ? 'is-invalid' : ''"
							name="suburb_name"
							autocomplete="off"
							class="form-control"
							@change="handleSuburbChange">
							<option
								disabled
								value="">
								{{ translate('select_suburb') }}
							</option>
							<option
								v-for="(suburb, index) in suburbsList"
								:key="index"
								:value="index">
								{{ suburb.attributes.suburb_name }}
							</option>
						</select>
						<template v-if="hasError('address3')">
							<span
								v-for="error in errors[`${prefixErrors}address3`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<input-text
						:id="'city'"
						:required="true"
						:label="translate('municipality_city')"
						:setter-value="form.city"
						:errors="errors[`${prefixErrors}city`]"
						type="text"
						readonly
						disabled
						autocomplete="address-level2"
						@dataChanged="form.city = $event" />
				</b-col>
				<b-col
					:md="window.width <= 800 ? '6' : '4'"
					lg="4"
					cols="12">
					<input-text
						:id="'state'"
						:required="true"
						:label="translate('state')"
						:setter-value="form.region_name"
						:errors="errors[`${prefixErrors}region_id`]"
						type="text"
						autocomplete="region_name"
						readonly
						disabled />
				</b-col>
				<b-col cols="12">
					<input-text
						:id="'address4'"
						:required="false"
						:disabled="form.is_pickup_address"
						:label="translate('reference')"
						:setter-value="form.address4"
						:errors="errors[`${prefixErrors}address4`]"
						:max="25"
						type="text"
						autocomplete="address-line4"
						@dataChanged="form.address4 = $event" />
				</b-col>
				<b-col
					v-if="displayOverrideToggle"
					:class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : ''"
					cols="12">
					<switch-toggle
						id="override_validation"
						v-model="form.override_validation"
						name="override_validation"
						variant="success"
						class="mr-2"
						pill />
					<label for="override_validation">
						{{ translate('override_validation') }}
						<span
							v-b-tooltip.hover
							:title="translate('override_validation_info')"
							class="text-muted">
							<i class="fas fa-lg fa-info-circle" />
						</span>
					</label>
				</b-col>
				<b-col
					v-if="displaySubmitButton || displayCancelButton || displayGobackButton"
					class="mt-3">
					<button
						v-if="displaySubmitButton"
						class="btn ml-2  btn-primary float-right"
						@click="$emit('on-submit', form)">
						{{ translate('submit') }}
					</button>
					<button
						v-if="displayCancelButton"
						class="btn btn-secondary b-4 float-right"
						@click="$emit('on-cancel')">
						{{ translate('cancel') }}
					</button>
					<button
						v-if="displayGobackButton"
						class="btn btn-secondary b-4 float-right"
						@click="$router.go(-1)">
						{{ translate('go_back') }}
					</button>
				</b-col>
			</b-row>
		</form>
	</div>
</template>
<script>
import { ADDRESSES } from '@/settings/AddressBook';
import InputText from '@/components/InputText/index.vue';
import SwitchToggle from '@/components/Switch/index.vue';
import { Profile, Addresses } from '@/translations';
import ElementResolution from '@/mixins/ElementResolution';
import WindowSizes from '@/mixins/WindowSizes';
import Country from '@/util/Country';
import EventBus from '@/util/eventBus';

export default {
	name: 'AddressFormType2',
	messages: [Profile, Addresses],
	components: { SwitchToggle, InputText },
	mixins: [ElementResolution, WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		sameAsBilling: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		prefixErrors: {
			type: String,
			default: '',
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		address: {
			type: String,
			default: ADDRESSES.default,
		},
		countryCodeImport: {
			type: String,
			default: '',
		},
		userCountry: {
			type: Boolean,
			default: false,
		},
		displayCancelButton: {
			type: Boolean,
			default: false,
		},
		displaySubmitButton: {
			type: Boolean,
			default: true,
		},
		displayOverrideToggle: {
			type: Boolean,
			default: false,
		},
		displayGobackButton: {
			type: Boolean,
			default: true,
		},
		allowChangeCountry: {
			type: Boolean,
			default: false,
		},
		hideCountryField: {
			type: Boolean,
			default: false,
		},
		hideNameFields: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			countryCode: this.countryCodeImport,
			selectedCountryIso: '',
			suburbs: new Country(),
			suburbIndex: '',
			internalNumber: '',
			externalNumber: '',
			loadedInitialInfo: false,
		};
	},
	computed: {
		suburbsList() {
			try {
				const { data } = this.suburbs.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		errorsImport(value) {
			this.errors = value;
		},
		countryCodeImport(value) {
			if (value !== '') {
				this.countryCode = value;
			} else {
				this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
			}
		},
		internalNumber() {
			this.formatAddress2();
		},
		externalNumber() {
			this.formatAddress2();
		},
		'formImport.address2': function onAddress2Change() {
			this.loadInitialFormData();
		},
		formImport: {
			handler(value) {
				this.form = value;
				this.loadInitialFormData();
			},
			deep: true,
		},
		suburbsList: {
			handler() {
				if (this.suburbIndex !== '' && this.suburbsList >= 0) {
					this.handleSuburbChange();
				}
			},
			deep: true,
		},
	},
	mounted() {
		if (!this.userCountry) this.form.country_code = '';
		if (!this.countryCode) this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
		EventBus.$on('callAddressRequestData', () => {
			this.addressInfoSubmit();
		});
	},
	methods: {
		addressInfoSubmit() {
			this.$emit('requestData', this.form);
		},
		hasError(field) {
			if (typeof this.errors[`${this.prefixErrors}${field}`] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		loadInitialFormData() {
			if (this.formImport.address2.length > 0) {
				const numbers = this.formImport.address2.split(',');
				// eslint-disable-next-line prefer-destructuring
				this.externalNumber = numbers[0];
				this.internalNumber = numbers[1] || '';
			}
		},
		formatAddress2() {
			let address2 = this.externalNumber;
			if (this.internalNumber) {
				address2 += `,${this.internalNumber}`;
			}
			this.form.address2 = address2;
		},
		async handlePostCodeChange(postCode) {
			this.suburbs.clear();
			this.suburbIndex = '';
			if (postCode.length > 4) {
				await this.suburbs.getSuburbs(postCode);
			}
			this.form.postcode = postCode;
			this.clearError('city');
			this.clearError('address3');
			this.clearError('postcode');
			this.clearError('region_id');

			if (this.formImport.address3 !== '' && !this.loadedInitialInfo) {
				// Find in this.suburbsList the index of this.formImport.city value
				const index = this.suburbsList.findIndex((suburb) => suburb.attributes.suburb_name === this.formImport.address3);
				if (index !== -1) {
					this.suburbIndex = index;
					this.loadedInitialInfo = true;
					this.handleSuburbChange();
				}
			} else {
				this.form.city = '';
				this.form.address3 = '';
				this.form.region_name = '';
				this.form.region_id = '';
			}
		},
		handleSuburbChange() {
			if (this.suburbIndex !== '' && this.suburbsList.length > 0) {
				this.form.city = this.suburbsList[this.suburbIndex].attributes.city;
				this.form.address3 = this.suburbsList[this.suburbIndex].attributes.suburb_name;
				this.form.region_name = this.suburbsList[this.suburbIndex].attributes.state;
				this.form.region_id = this.suburbsList[this.suburbIndex].attributes.state_id;
				this.form.state_code = this.suburbsList[this.suburbIndex].attributes.state_code;
			} else {
				this.form.city = '';
				this.form.address3 = '';
				this.form.region_name = '';
				this.form.region_id = '';
				this.form.state_code = '';
			}
			this.clearError('city');
			this.clearError('address3');
			this.clearError('postcode');
			this.clearError('region_id');
		},
	},
};
</script>
