<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div v-if="!loadingPaymentMethods">
			<div class="row">
				<div class="col-12">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item border-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div
									:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
									class="btn-group-lg payment-method"
									role="group">
									<template v-for="item in paymentMethods">
										<b-button
											:key="item.id"
											:variant="selectedPaymentMethodName === item.attributes.method_code_name ? 'primary' : 'light'"
											@click="selectedPaymentMethodName = item.attributes.method_code_name">
											{{ translate(item.attributes.method_code_name) }}
										</b-button>
									</template>
								</div>
							</div>
						</div>
					</li>
					<b-row
						v-if="canSplitWithWallet && canSplitWithWalletConsideringMinAmount && allowedPaymentMethodsWithWallet.includes(selectedPaymentMethodName)"
						class="mx-1">
						<b-col v-if="selectedPaymentMethodName">
							<switch-toggle
								id="split_payment"
								v-model="splitWithWallet"
								name="wallet_split"
								variant="success"
								class="mr-2"
								pill />
							<label for="wallet_split">
								{{ translate('split_between_two_payment_methods', { first: translate('cashondelivery'), second: translate(selectedPaymentMethodName) }) }}
							</label>
							<hr class="p-1 m-1">
						</b-col>
						<b-col
							v-if="showSectionsAsCards"
							cols="12">
							<b-alert
								show
								v-html="translate('split_with_wallet_instructions')" />
						</b-col>
					</b-row>
					<form
						@submit.prevent="showSplitPaymentMessages() ? confirmSplitPayment() : prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="handleAddressFormChange($event.target.name);">
						<ul
							:class="{ 'mx-3': showSectionsAsCards }"
							class="list-group">
							<li
								v-if="showSectionsAsCards"
								class="list-group-item h-100 p-0">
								<div
									class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
									<div class="col my-auto align-middle">
										<div class="h5 m-0">
											{{ translate('cashondelivery') }}
										</div>
									</div>
								</div>
								<div class="px-4 py-2">
									<b-row>
										<b-col cols="12">
											<wallet-payment
												:show-title="false"
												:wallet-balance-loading="walletBalanceLoading"
												:balance="balance"
												:wallet-type="walletType"
												:wallet-type-errors="validationErrors[`${currentValidation}.payment_method.wallet_type`]"
												:wallet-password-errors="validationErrors[`${currentValidation}.payment_method.wallet_password`]"
												@passwordChanged="walletPassword = $event" />
										</b-col>
										<b-col cols="6">
											<label
												for="amount"
												class="label">
												{{ translate('amount_to_pay_with_payment_method', { method: translate('cashondelivery') }) }}
												<span class="text-danger">*</span>
											</label>
										</b-col>
										<b-col cols="6">
											<input
												v-model="walletSplitAmount"
												name="amount"
												type="number"
												class="form-control text-right"
												aria-describedby="amount"
												placeholder="$0.00"
												@input="verifyAndFixWalletAmount">
										</b-col>
										<b-col cols="12">
											<template v-if="hasAmountError">
												<p
													v-for="error in validationErrors[`${currentValidation}.payment_method.wallet_split_amount`]"
													:key="error"
													class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
													v-text="error" />
											</template>
										</b-col>
									</b-row>
								</div>
							</li>
							<li
								:class="{ 'border-0': !showSectionsAsCards }"
								class="list-group-item h-100 p-0">
								<div
									v-if="showSectionsAsCards"
									class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
									<div class="col my-auto align-middle">
										<div class="h5 m-0">
											{{ translate(selectedPaymentMethodName) }}
										</div>
									</div>
								</div>
								<div class="pb-2">
									<b-row>
										<b-col
											v-if="showSectionsAsCards"
											cols="12">
											<div class="d-flex justify-content-between align-items-center mt-3 px-4">
												<div>
													{{ translate('amount_to_pay_with_payment_method', { method: translate(selectedPaymentMethodName) }) }}:
												</div>
												<div>
													<b>{{ remainingForOtherMethod | currency('usd', translate) }}</b>
												</div>
											</div>
										</b-col>
										<b-col>
											<ul class="list-group w-100">
												<li class="list-group-item border-0 rounded-0 mt-1 pb-0">
													<credit-card-payment-register-v2
														v-if="isCardPaymentMethod"
														ref="creditCardV2Register"
														:show-title="paymentMethods.length === 1"
														:payment-method-conditions="conditions"
														:can-split-payment="canSplitPayment && !splitWithWallet"
														:split-payment-import="splitPayment"
														:split-payment-amount-import="splitPaymentAmount"
														:cart-total="cartTotal"
														:form="form"
														@preparing="preparing = $event" />
													<wallet-payment
														v-if="selectedPaymentMethodName === 'cashondelivery'"
														:show-title="paymentMethods.length === 1"
														:wallet-balance-loading="walletBalanceLoading"
														:balance="balance"
														:wallet-type="walletType"
														:wallet-type-errors="validationErrors[`${currentValidation}.payment_method.wallet_type`]"
														:wallet-password-errors="validationErrors[`${currentValidation}.payment_method.wallet_password`]"
														@passwordChanged="walletPassword = $event" />
													<cash-payment
														v-if="selectedPaymentMethodName === 'checkmo'"
														:show-title="paymentMethods.length === 1"
														:payment-method-conditions="conditions"
														redirect-to="RegisterPack" />
													<bitcoin-payment
														v-if="selectedPaymentMethodName === 'banktransfer'"
														:show-title="paymentMethods.length === 1"
														:payment-method-conditions="conditions"
														redirect-to="RegisterPack" />
													<sezzle-payment
														v-if="selectedPaymentMethodName === 'sezzle'"
														:show-title="paymentMethods.length === 1"
														:payment-method-conditions="conditions"
														redirect-to="RegisterPack"
														sezzle-details="sezzle_details" />
													<oxxo-payment
														v-if="selectedPaymentMethodName === 'oxxopay'"
														:show-title="paymentMethods.length === 1"
														:payment-method-conditions="conditions"
														redirect-to="RegisterPack" />
													<g-cash-payment
														v-if="selectedPaymentMethodName === 'gcash'"
														:show-title="paymentMethods.length === 1"
														:payment-method-conditions="conditions"
														redirect-to="RegisterPack" />
													<pay-pal-payment
														v-if="selectedPaymentMethodName === 'lptpaypal'"
														:show-title="paymentMethods.length === 1"
														:payment-method-conditions="conditions"
														redirect-to="RegisterPack"
														paypal-details="lptpaypal_details" />
												</li>
												<li
													v-if="conditions.isAvailable"
													class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pb-0">
													<div class="row">
														<div class="col-12">
															<div class="h4 mb-2">
																{{ translate('billing_information') }}
															</div>
														</div>
													</div>
													<div class="row">
														<div
															v-if="isCardPaymentMethod"
															class="col-12">
															<b-alert show>
																{{ translate('billing_disclaimer') }}
															</b-alert>
														</div>
														<div class="col-12">
															<div class="custom-control custom-checkbox">
																<input
																	id="customCheck1"
																	v-model="sameAs"
																	type="checkbox"
																	class="custom-control-input">
																<label
																	class="custom-control-label"
																	for="customCheck1">{{ translate('same_as_shipping') }}</label>
															</div>
														</div>
													</div>
													<div v-if="!sameAs">
														<address-country-form
															:user-country="true"
															:form-import.sync="form"
															:errors-import="validationErrors"
															:prefix-errors="`${currentValidation}.billing.`"
															:country-code-import="registerCountry"
															:display-cancel-button="false"
															:display-submit-button="false"
															:display-goback-button="false" />
													</div>
												</li>
											</ul>
										</b-col>
									</b-row>
								</div>
							</li>
						</ul>
					</form>
				</div>
			</div>
			<b-row
				v-if="showSectionsAsCards"
				no-gutters
				class="px-3 mt-3">
				<b-col>
					<b-alert
						show
						class="pb-1 mb-0"
						variant="warning">
						<div class="form-check">
							<input
								id="walletAndOtherMethodTerms"
								v-model="walletSplitAgreement"
								name="walletAndOtherMethodTerms"
								class="form-check-input"
								type="checkbox">
							<label for="walletAndOtherMethodTerms">
								{{ translate('wallet_and_other_payment_method_terms', { name: appName }) }}
							</label>
						</div>
					</b-alert>
				</b-col>
			</b-row>
			<li class="list-group-item border-0 rounded-0 pt-3 pb-0">
				<div class="row no-gutters justify-content-end">
					<!-- <div
						v-if="currentStep !== 2"
						:class="{ 'pr-2': currentStep !== 2 }"
						class="col">
						<b-button
							variant="secondary"
							size="lg"
							:style="!['xs'].includes(windowWidth) ? 'min-width: 150px;' : ''"
							:class="{ 'w-100': ['xs'].includes(windowWidth) }"
							class="float-md-right"
							@click="$emit('cancel')">
							{{ translate('cancel') }}
						</b-button>
					</div> -->
					<div
						:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
						:class="currentStep !== 2 ? 'col-6' : 'col-12'"
						class="col col-md-3">
						<b-button
							:disabled="(showSectionsAsCards && !walletSplitAgreement) || !splitPaymentAgreementState() || validating || !!preparing || !conditions.isAvailable"
							variant="primary"
							size="lg"
							:style="currentStep !== 2 ? '' : 'min-width: 200px;'"
							class="w-100"
							@click="showSplitPaymentMessages() ? confirmSplitPayment() : prepareForStorage()">
							<i
								v-if="validating || !!preparing"
								class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
						</b-button>
					</div>
				</div>
			</li>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loadingPaymentMethods"
			:has-data="!!paymentMethods.length"
			class="mx-auto mt-4" />
		<same-address-as-card-statement-modal
			v-model="showSameAddressAsCardStatementModal"
			:country-to-check="registerCountry"
			:card-type="selectedCardInfo.card_type"
			:card-type2="selectedCardInfo.card_type_2"
			:last-digits="selectedCardInfo.last_digits"
			:last-digits2="selectedCardInfo.last_digits_2"
			:address="selectedCardInfo.address"
			@confirm="savePaymentStep()" />
	</div>
</template>
<script>
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import {
	ALLOWED_PAYMENT_METHODS_WITH_WALLET,
	MIN_AMOUNT_BY_PAYMENT_METHODS_WITH_WALLET,
} from '@/settings/Purchase';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE,
} from '@/settings/Errors';
import WindowSizes from '@/mixins/WindowSizes';
import IsLoading from '@/components/Loading';
import SwitchToggle from '@/components/Switch';
import Country from '@/util/Country';
import Wallets from '@/util/Wallets';
import Cart from '@/util/Cart';
import CommonMix from '../../mixins/Common';
import IdentityV2 from '../../mixins/IdentityV2';
import Steps from '../../mixins/Steps';
import CashPayment from '@/components/Payment/CashPayment.vue';
import BitcoinPayment from '@/components/Payment/BitcoinPayment.vue';
import SezzlePayment from '@/components/Payment/SezzlePayment.vue';
import WalletPayment from '@/components/Payment/WalletPayment.vue';
import OxxoPayment from '@/components/Payment/OxxoPayment.vue';
import GCashPayment from '@/components/Payment/GCashPayment.vue';
import PayPalPayment from '@/components/Payment/PayPalPayment.vue';
import CreditCardPaymentRegisterV2 from '@/components/Payment/CreditCardPaymentRegisterV2.vue';
import { currency } from '@/config/Filters';
import SameAddressAsCardStatementModal from '@/components/SameAddressAsCardStatementModal/index.vue';
import AddressCountryForm from '@/components/AddressCountryForm';
import { CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';

export default {
	name: 'PaymentEdit',
	components: {
		AddressCountryForm,
		SameAddressAsCardStatementModal,
		IsLoading,
		SwitchToggle,
		CashPayment,
		BitcoinPayment,
		SezzlePayment,
		WalletPayment,
		OxxoPayment,
		GCashPayment,
		PayPalPayment,
		CreditCardPaymentRegisterV2,
	},
	filters: {
		currency,
	},
	mixins: [CommonMix, IdentityV2, Steps, WindowSizes],
	props: {
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		cartTotal: {
			type: Number,
			default: 0,
		},
		canSplitPayment: {
			type: Boolean,
			default: false,
		},
		walletSplit: {
			type: Boolean,
			default: false,
		},
		canSplitWithWallet: {
			type: Boolean,
			default: false,
		},
		excludedPaymentMethods: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			paymentMethodData: new Cart(),
			wallets: new Wallets(),
			selectedPaymentMethodName: 0,
			sameAs: false,
			sponsor: 0,
			identityId: null,
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
			},
			walletPassword: '',
			walletType: '',
			allowAnotherCountry,
			alert: new this.$Alert(),
			appName: process.env.VUE_APP_NAME,
			billingAddressError: false,
			splitPayment: false,
			splitPaymentAmount: null,
			allowedPaymentMethodsWithWallet: ALLOWED_PAYMENT_METHODS_WITH_WALLET,
			splitWithWallet: this.walletSplit,
			walletSplitAmount: '',
			walletSplitAgreement: false,
			minAmountByPaymentMethodWithWallet: MIN_AMOUNT_BY_PAYMENT_METHODS_WITH_WALLET,
			validation: {},
			showSameAddressAsCardStatementModal: false,
		};
	},
	computed: {
		selectedCardInfo() {
			// eslint-disable-next-line camelcase
			return this.validation?.card_data ?? {};
		},
		isCardPaymentMethod() {
			return CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName);
		},
		hasAmountError() {
			try {
				return !!this.validationErrors[`${this.currentValidation}.payment_method.wallet_split_amount`].length;
			} catch (e) {
				return false;
			}
		},
		showSectionsAsCards() {
			return this.splitWithWallet && this.allowedPaymentMethodsWithWallet.includes(this.selectedPaymentMethodName);
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.registerCountry);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		paymentMethods() {
			try {
				const paymentMethods = this.paymentMethodData.data.response.data.data;
				return paymentMethods.filter(({ attributes: { method_code_name: methodCodeName } }) => !this.excludedPaymentMethods.includes(methodCodeName));
			} catch (error) {
				return [];
			}
		},
		balance() {
			try {
				return this.wallets.data.response.data.response.total_balance;
			} catch (error) {
				return {};
			}
		},
		walletBalanceLoading() {
			return !!this.wallets.data.loading;
		},
		loadingPaymentMethods() {
			return this.paymentMethodData.data.loading;
		},
		canSplitWithWalletConsideringMinAmount() {
			const minAmount = this.minAmountByPaymentMethodWithWallet[this.selectedPaymentMethodName] ?? 0;
			return this.cartTotal > minAmount;
		},
		remainingForOtherMethod() {
			return Number(this.cartTotal - this.walletSplitAmount);
		},
		conditions() {
			const conditions = { ...(this.paymentMethodConditions[this.selectedPaymentMethodName] || { isAvailable: true }) };
			if (Object.keys(conditions).length > 0 && typeof conditions.max !== 'undefined' && this.showSectionsAsCards) {
				conditions.isAvailable = this.remainingForOtherMethod <= conditions.max;
			}
			return conditions;
		},
	},
	watch: {
		sameAs: function sameAs(value) {
			if (value) {
				this.clearValidationError(true);
				this.billingAddressError = false;
				const { shipping } = this.getStepInformation('RegisterShipping');
				Object.keys(shipping.shipping_address).forEach((property) => {
					if (property !== 'signature_required') this.form[property] = shipping.shipping_address[property];
				});
			} else {
				if (!this.billingAddressError) {
					Object.keys(this.form).forEach((key) => {
						this.form[key] = '';
					});
				}
				this.form.country_code = this.registerCountry;
			}
		},
		splitWithWallet(split) {
			this.$emit('update:walletSplit', split);
		},
		canSplitWithWalletConsideringMinAmount(canSplitWithWallet) {
			if (!canSplitWithWallet) {
				this.splitWithWallet = false;
			}
		},
		selectedPaymentMethodName() {
			if (!this.isCardPaymentMethod) {
				this.preparing = false;
			}
		},
	},
	mounted() {
		this.paymentMethodData.getPaymentMethods({ country_code: this.getRegisterCountry() }).then(() => {
			if (this.selectedPaymentMethodName === 0) {
				this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
			}
			if (this.paymentMethods.some((method) => method.attributes.method_code_name === 'cashondelivery')) {
				this.walletType = WALLET_TYPES.commission;
				this.wallets.getBalance();
			}
			const cashIndex = this.paymentMethods.findIndex((x) => x.attributes.method_code_name === 'checkmo');
			if (cashIndex >= 0 && this.isJns()) {
				this.paymentMethods.splice(cashIndex, 1);
			}

			if (this.excludedPaymentMethods.includes(this.selectedPaymentMethodName)) {
				// eslint-disable-next-line camelcase
				this.selectedPaymentMethodName = this.paymentMethods[0]?.attributes?.method_code_name ?? '';
			}
		}).finally(() => {
			// TODO: Set the VGSCollect.create callback to get rid of this timeout
			// https://www.verygoodsecurity.com/docs/vgs-collect/js/integration
			let timeout = 1000;
			if (this.isCardPaymentMethod) {
				timeout = 3000;
			}

			// Waiting for LP Form
			this.preparing = true;
			setTimeout(() => {
				this.preparing = false;
			}, timeout);
		});
	},
	created() {
		const replicatedUsername = this.$replicated.replicatedId();
		this.countryData.getCountries({ replicated_site: replicatedUsername });
		this.getGeneralInformation();
		this.form.country_code = this.registerCountry;
		this.stateData.getStates(this.form.country_code);
		this.setStoredSameAs();
		this.getStoredInformation();
		return null;
	},
	methods: {
		handleContinue() {
			if (this.isCardPaymentMethod) {
				this.showSameAddressAsCardStatementModal = true;
			} else {
				this.savePaymentStep();
			}
		},
		savePaymentStep() {
			this.saveStep(this.validation);
		},
		splitPaymentAgreementState() {
			if (['purchaseorder', 'purchaseorderintl'].includes(this.selectedPaymentMethodName)) {
				return this.$refs.creditCardV2Register?.splitPaymentAgreementState;
			}
			return true;
		},
		showSplitPaymentMessages() {
			return this.$refs.creditCardV2Register?.showSplitPaymentMessages ?? false;
		},
		verifyAndFixWalletAmount() {
			const minAmount = this.minAmountByPaymentMethodWithWallet[this.selectedPaymentMethodName] ?? 0;
			const maxWalletAmount = Number(Math.max(this.cartTotal - minAmount, 0).toFixed(2));

			if (this.walletSplitAmount > maxWalletAmount) {
				this.walletSplitAmount = maxWalletAmount;
			}
		},
		setStoredSameAs() {
			const paymentInformation = this.getStepInformation('RegisterPayment');
			if (paymentInformation.payment !== undefined) {
				const { same_as_shipping: sameAs } = { ...paymentInformation.payment.billing };
				if (sameAs === true) {
					const shippingInformation = this.getStepInformation('RegisterShipping');
					const { shipping } = shippingInformation;
					Object.keys(shipping.shipping_address).forEach((key) => {
						this.form[key] = shipping.shipping_address[key];
					});
				}
				this.sameAs = sameAs;
			}
		},
		getGeneralInformation() {
			const shippingInformation = this.getStepInformation('RegisterShipping');
			const {
				sponsor, identity_id: identityId,
			} = shippingInformation;
			try {
				const { shipping_address: shippingAddress } = shippingInformation.shipping;
				if (typeof shippingAddress === 'undefined' || typeof sponsor === 'undefined') {
					this.goToStep(2);
					return null;
				}
			} catch (error) {
				this.goToStep(2);
				return null;
			}

			this.sameAs = true;
			this.identityId = identityId;
			this.sponsor = sponsor;

			return null;
		},
		getStoredInformation() {
			const { payment } = { ...this.getStepInformation('RegisterPayment') };
			if (typeof payment !== 'undefined' && typeof payment.payment_method !== 'undefined') {
				this.selectedPaymentMethodName = payment.payment_method.name;
				if (this.selectedPaymentMethodName === 'checkmo') {
					this.paymentMethodData.getPaymentMethods({ country_code: this.getRegisterCountry() }).then(() => {
						this.paymentMethods.splice(this.paymentMethods.findIndex((x) => x.attributes.method_code_name === 'checkmo'), 1);
						this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
					});
				}
				if (['purchaseorder', 'purchaseorderintl'].includes(this.selectedPaymentMethodName) && !!payment.payment_method.split_amount) {
					this.splitPayment = true;
					this.splitPaymentAmount = payment.payment_method.split_amount;
				}
				// eslint-disable-next-line camelcase
				this.walletSplitAmount = payment.payment_method?.wallet_split_amount || 0;
			}
			try {
				const { billing: data } = payment;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
					}
				});
				if (countryCode !== this.registerCountry) {
					this.form.region_id = '';
				}
			} catch (error) {
				return null;
			}
			return null;
		},
		confirmSplitPayment() {
			const trans = {
				title: this.translate('important_note').toUpperCase(),
				text: this.translate('split_payment_disclaimer'),
			};

			const options = {
				config: {
					icon: 'warning',
					confirmButtonText: this.translate('understand'),
					cancelButtonText: this.translate('cancel'),
					allowOutsideClick: false,
					allowEscapeKey: false,
					confirmButtonColor: '#eb772f',
					showCancelButton: true,
					reverseButtons: true,
				},
			};

			const alert = new this.$Alert();
			alert.confirmation(trans.title, trans.text, options).then(() => {
				this.prepareForStorage();
			}).catch(() => {});
		},
		async prepareForStorage() {
			this.preparing = true;

			let paymentMethod = { name: this.selectedPaymentMethodName };

			if (this.isCardPaymentMethod) {
				// Get Lacore token or tokens
				await this.$refs.creditCardV2Register.lacoreV2HandleResponse();

				// Use single payment
				if (!this.$refs.creditCardV2Register?.splitPayment) {
					paymentMethod.token = this.$refs.creditCardV2Register.lacoreV2PaymentToken;
				}

				// Set multiple credit card tokens and amounts if selecting split payments
				if (this.$refs.creditCardV2Register?.splitPayment) {
					paymentMethod.split_amount = this.$refs.creditCardV2Register.splitPaymentAmount;
					this.$refs.creditCardV2Register.lacoreV2PaymentTokens.forEach((token, index) => {
						// Generate and fill token parameter keys as such:
						// 	payment.token
						// 	payment.token_2
						// 	payment.token_3
						// 	...
						const tokenKeySuffix = index > 0 ? `_${index + 1}` : '';
						const tokenKey = `token${tokenKeySuffix}`;
						paymentMethod[tokenKey] = token;
					});
				}

				// eslint-disable-next-line no-underscore-dangle
				paymentMethod.sift_session_id = window._sift_session_id;

				// Create v2 identity if using CCv2.
				// It is created here instead of in Personal Information because we must use a shipping address
				try {
					await this.lacoreV2CreateIdentity();
				} catch (error) {
					// Stop validation if there was a problem creating identity
					this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
					this.preparing = false;
					return null;
				}
			} else if (['oxxopay', 'sezzle', 'lptpaypal'].includes(this.selectedPaymentMethodName)) {
				// eslint-disable-next-line no-underscore-dangle
				paymentMethod.sift_session_id = window._sift_session_id;
			} else if (this.selectedPaymentMethodName === 'cashondelivery') {
				paymentMethod.wallet_password = this.walletPassword;
				paymentMethod.wallet_type = this.walletType;
			}

			const walletSplitSettings = this.showSectionsAsCards ? {
				wallet_split: this.splitWithWallet,
				wallet_split_amount: this.walletSplitAmount || 0,
				wallet_password: this.walletPassword,
				wallet_type: WALLET_TYPES.commission,
			} : { wallet_split: false };

			paymentMethod = { ...paymentMethod, ...walletSplitSettings };
			this.validation = {
				step: this.currentValidation,
				sponsor: this.sponsor,
				identity_id: this.identityId,
				payment: {
					billing: {
						...this.form,
						same_as_shipping: this.sameAs,
					},
					payment_method: paymentMethod,
				},
			};

			this.validateStep(this.validation).then((response) => {
				this.validation = { ...this.validation, card_data: { ...response.response } };
				if (!(this.$refs.creditCardRegister?.lacoreV1Failed ?? false) && !(this.$refs.creditCardV2Register?.lacoreV2Failed ?? false)) {
					this.handleContinue();
				}
			}).catch((error) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					if (typeof this.validationErrors.cart_id !== 'undefined') {
						let response = '';
						this.validationErrors.cart_id.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('cartValidationError');
						}, 6000);
					}
					if (typeof this.validationErrors.identity_id !== 'undefined') {
						this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
					}

					// Split CC payment amount errors
					if (
						typeof this.validationErrors[`${this.currentValidation}.payment_method.split_amount`] !== 'undefined'
						&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
					) {
						this.$refs.creditCardV2Register.amountErrors = this.validationErrors[`${this.currentValidation}.payment_method.split_amount`];
					}

					// Single CC payment and Split CC payment #1 errors
					if (
						typeof this.validationErrors[`${this.currentValidation}.payment_method.token`] !== 'undefined'
						&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
						&& !this.$refs.creditCardV2Register.lacoreV2Failed
					) {
						this.$refs.creditCardV2Register.lacoreV2CreditCardErrors.number.errorMessages = this.validationErrors[`${this.currentValidation}.payment_method.token`];
					}

					// Split CC payment #2 errors
					if (
						typeof this.validationErrors[`${this.currentValidation}.payment_method.token_2`] !== 'undefined'
						&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
						&& !this.$refs.creditCardV2Register.lacoreV2Failed
					) {
						this.$refs.creditCardV2Register.lacoreV2CreditCard2Errors.number.errorMessages = this.validationErrors[`${this.currentValidation}.payment_method.token_2`];
					}

					// CC v2 payment errors
					if (
						typeof this.validationErrors[`${this.currentValidation}.payment_method.token`] !== 'undefined'
						&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
						&& !this.$refs.creditCardV2Register.lacoreV2Failed
					) {
						this.$refs.creditCardV2Register.lacoreV2CreditCardErrors.number.errorMessages = this.validationErrors[`${this.currentValidation}.payment_method.token`];
					}

					const errorKeys = Object.keys(this.validationErrors);
					const match = errorKeys.filter((errorKey) => errorKey.search('billing') > 0);
					this.billingAddressError = match.length > 0;
					if (this.billingAddressError) {
						this.sameAs = false;
					}
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
	},
};
</script>
