<template>
	<div>
		<div class="row">
			<div class="col-12">
				<form
					@submit.prevent="validateForm()"
					@keydown="clearValidationError($event.target.name);"
					@change="clearValidationError($event.target.name);">
					<li
						v-if="showSponsor"
						class="list-group-item border-left-0 border-right-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div class="mb-2">
									<span class="h4">{{ translate('sponsor_information') }} </span><span class="h4 text-danger">*</span>
									<span
										v-b-tooltip.hover.html.right
										:title="translate('sponsor_description', {company: companyName, supportLink: companyEmail, support: companyEmail })"
										class="text-muted small mx-2">
										<i class="fas fa-lg fa-info-circle" />
									</span>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<div class="form-group">
									<input-text
										id="sponsor"
										:placeholder="translate('sponsor_information')"
										:show-label="false"
										:setter-value="sponsorInfo.id"
										:errors="validationErrors['sponsor']"
										:required="true"
										type="text"
										autocomplete="sponsor"
										@dataChanged="sponsorInfo.id = $event" />
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div
									class="h4 pb-0 mb-0">
									{{ translate('personal_information') }}
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item border-top-0 border-left-0 border-right-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12 col-md-12">
								<div class="form-group">
									<div>
										<span>{{ translate('are_you_a_company') }}: </span>
										<switch-toggle
											v-model="isCompany"
											variant="success"
											pill
											class="ml-3" />
									</div>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.first_name`"
										:label="translate('first_name')"
										:setter-value="form.first_name"
										:errors="validationErrors[`${currentValidation}.first_name`]"
										:required="true"
										type="text"
										autocomplete="given-name"
										@dataChanged="form.first_name = $event" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.last_name`"
										:label="translate('last_name')"
										:setter-value="form.last_name"
										:errors="validationErrors[`${currentValidation}.last_name`]"
										:required="true"
										type="text"
										autocomplete="family-name"
										@dataChanged="form.last_name = $event" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.email`"
										:label="translate('email')"
										:setter-value="form.email"
										:errors="validationErrors[`${currentValidation}.email`]"
										:required="true"
										:max="maxEmailLength"
										type="text"
										autocomplete="email"
										@dataChanged="form.email = $event" />
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="mobile_number">
										{{ translate('mobile_number') }}
										<span class="text-danger">*</span>
									</label>
									<vue-tel-input
										:id="`${currentValidation}.mobile_number`"
										ref="mobileNumberInput"
										v-model="form.mobile_number"
										:required="true"
										:label="translate('mobile_number')"
										:placeholder="translate('mobile_number')"
										:class="validationErrors[`${currentValidation}.mobile_number`] ? 'is-invalid' : ''"
										v-bind="bindProps"
										type="text"
										:name="`${currentValidation}.mobile_number`"
										class="form-control rounded-1"
										autocomplete="tel"
										@dataChanged="form.mobile_number = $event" />
									<template v-if="validationErrors[`${currentValidation}.mobile_number`]">
										<span
											v-for="error in validationErrors[`${currentValidation}.mobile_number`]"
											:key="error"
											class="invalid-feedback animated fadeIn"
											v-text="error" />
									</template>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="gender">
										{{ translate('gender') }}
										<span class="text-danger">*</span>
									</label>
									<div class="row">
										<div class="col">
											<select
												id="gender"
												v-model="form.gender"
												:class="(typeof validationErrors[`${currentValidation}.gender`] !== 'undefined' ? 'is-invalid' : '')"
												type="text"
												name="gender"
												autocomplete="gender"
												class="form-control">
												<option
													v-for="genderName in genders"
													:key="genderName"
													:value="genderName">
													{{ translate(genderName) }}
												</option>
											</select>
										</div>
									</div>
									<template v-if="(typeof validationErrors[`${currentValidation}.gender`] !== 'undefined')">
										<span
											v-for="error in validationErrors[`${currentValidation}.gender`]"
											:key="error"
											class="custom-invalid-feedback animated fadeIn text-danger"
											v-text="error" />
									</template>
								</div>
							</div>
							<div class="col-12 col-md-6">
								<div class="form-group">
									<label for="birthdate">
										{{ translate('birthdate') }}
										<span class="text-danger">*</span>
									</label>
									<div class="row">
										<div class="col pr-0 pr-lg-3">
											<select
												id="year"
												v-model="form.birthdate.year"
												:class="(typeof validationErrors[`${currentValidation}.birthdate`] !== 'undefined' ? 'is-invalid' : '')"
												type="text"
												name="year"
												autocomplete="bday-year"
												class="form-control">
												<option
													v-for="year in years()"
													:key="year"
													:value="year">
													{{ year }}
												</option>
											</select>
										</div>
										<div class="col px-0 px-lg-3">
											<select
												id="month"
												v-model="form.birthdate.month"
												:class="(typeof validationErrors[`${currentValidation}.birthdate`] !== 'undefined' ? 'is-invalid' : '')"
												type="text"
												name="month"
												autocomplete="bday-month"
												class="form-control">
												<option
													v-for="month in months"
													:key="month.key"
													:value="month.value">
													{{ translate(month.key) }}
												</option>
											</select>
										</div>
										<div class="col pl-0 pl-lg-3">
											<select
												id="day"
												v-model="form.birthdate.day"
												:class="(typeof validationErrors[`${currentValidation}.birthdate`] !== 'undefined' ? 'is-invalid' : '')"
												type="text"
												name="day"
												autocomplete="bday-day"
												class="form-control">
												<option
													v-for="day in 31"
													:key="day"
													:value="day <= 9 ? `0${day}` : day">
													{{ day | withZero }}
												</option>
											</select>
										</div>
									</div>
									<template v-if="(typeof validationErrors[`${currentValidation}.birthdate`] !== 'undefined')">
										<span
											v-for="error in validationErrors[`${currentValidation}.birthdate`]"
											:key="error"
											class="custom-invalid-feedback animated fadeIn text-danger"
											v-text="error" />
									</template>
								</div>
							</div>
							<div
								v-if="!isCompany && isNINRequired()"
								class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.national_identification_number`"
										:label="translate(`${userCountry}`)"
										:setter-value="form.national_identification_number"
										:errors="validationErrors[`${currentValidation}.national_identification_number`]"
										:required="true"
										type="text"
										autocomplete="off"
										@dataChanged="form.national_identification_number = $event" />
								</div>
							</div>
							<div
								v-if="isCompany"
								class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.tax_id`"
										:label="translate('tax_id')"
										:setter-value="form.tax_id"
										:errors="validationErrors[`${currentValidation}.tax_id`]"
										:required="true"
										type="text"
										autocomplete="off"
										@dataChanged="form.tax_id = $event" />
								</div>
							</div>
							<div
								v-if="isCompany"
								class="col-12 col-md-6">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.company`"
										:label="translate('company')"
										:setter-value="form.company"
										:errors="validationErrors[`${currentValidation}.company`]"
										:required="true"
										type="text"
										autocomplete="organization"
										@dataChanged="form.company = $event" />
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item border-left-0 border-right-0 rounded-0">
						<div class="row">
							<div class="col-12">
								<div
									class="h4 pb-0 mb-0">
									{{ translate('account_info') }}
								</div>
							</div>
						</div>
					</li>
					<li
						class="list-group-item border-0 rounded-0 py-0">
						<div class="row">
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.username`"
										:label="translate('username')"
										:setter-value="form.username"
										:errors="validationErrors[`${currentValidation}.username`]"
										:required="true"
										type="text"
										autocomplete="off"
										@dataChanged="form.username = $event">
										<template slot="afterLabelInline">
											<span
												v-b-tooltip.hover.html.right
												:title="translate('username_used_for_replicated_site', { website: `${website}/${form.username || translate('username')}` })"
												class="text-muted small mx-2">
												<i class="fas fa-lg fa-info-circle" />
											</span>
										</template>
									</input-text>
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.password`"
										:label="translate('password')"
										:setter-value="form.password"
										:errors="validationErrors[`${currentValidation}.password`]"
										:required="true"
										type="password"
										autocomplete="off"
										@dataChanged="form.password = $event" />
								</div>
							</div>
							<div class="col-12 col-md-4">
								<div class="form-group">
									<input-text
										:id="`${currentValidation}.password_confirmation`"
										:label="translate('password_confirmation')"
										:setter-value="form.password_confirmation"
										:errors="validationErrors[`${currentValidation}.password_confirmation`]"
										:required="true"
										type="password"
										class="text-nowrap"
										autocomplete="off"
										@dataChanged="form.password_confirmation = $event" />
								</div>
							</div>
						</div>
					</li>
				</form>
				<li class="list-group-item border-0 rounded-0 pt-0 pb-3">
					<div class="row no-gutters justify-content-end">
						<!-- <div
							v-if="currentStep !== 0"
							:class="{ 'pr-2': currentStep !== 0 }"
							class="col">
							<b-button
								variant="secondary"
								size="lg"
								:style="!['xs'].includes(windowWidth) ? 'min-width: 150px;' : ''"
								:class="{ 'w-100': ['xs'].includes(windowWidth) }"
								class="float-md-right"
								@click="$emit('cancel')">
								{{ translate('cancel') }}
							</b-button>
						</div> -->
						<div
							:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
							:class="currentStep !== 0 ? 'col-6' : 'col-12'"
							class="col col-md-3">
							<b-button
								:disabled="validating || openPhoneVerificationModal || preparing || creatingIdentity"
								variant="primary"
								size="lg"
								:style="currentStep !== 0 ? '' : 'min-width: 200px;'"
								class="w-100"
								@click="validateForm()">
								<i
									v-if="validating || preparing || creatingIdentity"
									class="fas fa-spinner fa-spin mr-2" />{{ translate('continue') }}
							</b-button>
						</div>
					</div>
				</li>
			</div>
		</div>
		<phone-verification-modal
			v-show="isPhoneVerificationRequired"
			:phone-number="form.mobile_number"
			:open.sync="openPhoneVerificationModal"
			recaptcha-action-name="register_verification"
			is-register
			@verified="handlePhoneVerified"
			@changeNumber="handleChangeMobileNumber" />
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import PhoneVerificationModal from '@/components/PhoneVerificationModal';
import InputText from '@/components/InputText';
import SwitchToggle from '@/components/Switch';
import PaymentProcessor from '@/util/PaymentProcessor';
import PhoneVerification from '@/util/PhoneVerification';
import { PHONE_VERIFICATION_STATES, VERIFY_PHONE_COUNTRIES } from '@/settings/PhoneVerification';
import { MAX_EMAIL_LENGTH } from '@/settings/Validations';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE,
} from '@/settings/Errors';
import { BLACKLIST_UNSET_FIELDS } from '@/settings/Purchase';
import { GENDERS as genders, REQUIRED_NIN_COUNTRIES } from '@/settings/Profile';
import { MONTHS as months } from '@/settings/Dates';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from '../../mixins/Common';
import SponsorMix from '../../mixins/Sponsor';
import Steps from '../../mixins/Steps';

export default {
	name: 'PersonalInformationEdit',
	components: {
		InputText,
		PhoneVerificationModal,
		SwitchToggle,
		VueTelInput,
	},
	filters: {
		withZero: (value) => ((value <= 9) ? `0${value}` : value),
	},
	mixins: [CommonMix, Steps, SponsorMix, WindowSizes],
	data() {
		return {
			alert: new this.$Alert(),
			paymentProcessor: new PaymentProcessor(),
			phoneVerification: new PhoneVerification(),
			isCompany: false,
			showSponsor: true,
			identityId: null,
			maxEmailLength: MAX_EMAIL_LENGTH,
			blackListFields: BLACKLIST_UNSET_FIELDS,
			website: process.env.VUE_APP_WEBSITE,
			bindProps: {
				mode: 'international',
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			form: {
				first_name: '',
				last_name: '',
				email: '',
				mobile_number: '',
				gender: genders[0],
				birthdate: {
					day: '01',
					month: '01',
					year: '1900',
				},
				national_identification_number: '',
				company: '',
				tax_id: '',
				username: '',
				password: '',
				password_confirmation: '',
			},
			genders,
			months,
			requiredNinCountries: REQUIRED_NIN_COUNTRIES,
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
			verificationPhoneToken: '',
			mobileNumberVerified: '',
			openPhoneVerificationModal: false,
			preparing: false,
			currentVerificationState: PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED,
		};
	},
	computed: {
		userCountry() {
			if (this.registerCountry) {
				return `nin_${this.registerCountry.toLowerCase()}`;
			}
			return 'national_identification_number';
		},
		isPhoneVerificationRequired() {
			return this.currentVerificationState === PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED
				&& VERIFY_PHONE_COUNTRIES.includes(this.registerCountry)
				&& !this.isTheSameMobileNumber();
		},
		creatingIdentity() {
			return this.paymentProcessor.data.loading;
		},
		isOrganizationVerified() {
			return this.phoneVerification.data.response.data.response.is_verified;
		},
	},
	watch: {
		'sponsorInfo.id': function sponsor(newVal) {
			this.showSponsor = newVal === '' || this.sponsorInfo.is_from_input;
		},
		isCompany(value) {
			if (value === false) {
				this.form.national_identification_number = this.form.tax_id;
				this.form.tax_id = '';
				this.form.company = '';
			}
			if (value === true) {
				this.form.tax_id = this.form.national_identification_number;
				this.form.national_identification_number = '';
			}
		},
	},
	created() {
		this.bindProps.defaultCountry = this.registerCountry;
		this.bindProps.onlyCountries = [this.registerCountry];
		this.getStoredInformation();
		this.initializePhoneVerification();
		this.getSponsorData();
	},
	methods: {
		years() {
			const startingYear = 1900;
			const currentYear = this.$moment().year();
			const years = [];
			/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
			for (let index = startingYear; index <= currentYear; index++) {
				years.push(index);
			}
			return years;
		},
		isTheSameMobileNumber() {
			return String(this.mobileNumberVerified).replaceAll(' ', '').localeCompare(String(this.form.mobile_number).replaceAll(' ', '')) === 0;
		},
		initializePhoneVerification() {
			this.verificationPhoneToken = '';
			if (VERIFY_PHONE_COUNTRIES.includes(this.registerCountry)) {
				this.currentVerificationState = PHONE_VERIFICATION_STATES.VERIFICATION_REQUIRED;
			} else {
				this.currentVerificationState = PHONE_VERIFICATION_STATES.VERIFICATION_NOT_REQUIRED;
			}
		},
		getStoredInformation() {
			const {
				sponsor,
				identity_id: identityId,
				mobile_number_verified: mobileNumberVerified,
			} = this.getStepInformation('RegisterPersonalInformation');
			const personalInfoSaved = { ...this.getStepInformation('RegisterPersonalInformation')[this.currentValidation] };
			if (!Object.keys(personalInfoSaved).length) {
				return null;
			}
			this.sponsorInfo.id = sponsor;
			this.identityId = identityId;
			this.mobileNumberVerified = mobileNumberVerified ?? '';

			const [year, month, day] = personalInfoSaved.birthdate.split('-');
			personalInfoSaved.birthdate = { year, month, day };

			Object.keys(this.form).forEach((key) => {
				if (typeof personalInfoSaved[key] !== 'undefined' && !this.blackListFields.includes(key)) {
					this.form[key] = personalInfoSaved[key];
				}
			});

			// Remove invalid fields for the current country
			const dataTemp = this.clearInvalidFields(personalInfoSaved);
			if (typeof dataTemp.is_company === 'boolean') {
				this.isCompany = dataTemp.is_company;
			}

			return null;
		},
		async validateForm() {
			this.preparing = true;
			const payload = this.buildStepValidationPayload();

			// If there is a user logged in, we do not need to check if the organization is verified
			if (!this.$user.auth()) {
				await this.phoneVerification.isOrganizationVerified(this.sponsorInfo.id);
			}

			this.validateStep(payload).then(() => {
				// Do not verify phone number if the sponsor is logged in (It means that the sponsor is already verified)
				if (!this.$user.auth() && this.isPhoneVerificationRequired && !this.isOrganizationVerified) {
					this.openPhoneVerification();
				} else {
					if (this.isTheSameMobileNumber()) {
						const { verification_phone_token: verificationPhoneToken } = this.getStepInformation('RegisterPersonalInformation');
						payload.verification_phone_token = verificationPhoneToken;
					}
					this.saveToStorage(payload);
				}
			}).catch((error) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					const { cart_id: cartId } = error.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('cartValidationError');
						}, 6000);
					}
				}
			}).finally(() => { this.preparing = false; });
		},
		saveToStorage(payload) {
			this.createIdentity().then(() => {
				payload.identity_id = this.identityId;
				this.saveStep(payload);
			}).catch(() => {
				this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
			});
		},
		handlePhoneVerified(token) {
			this.verificationPhoneToken = token;
			this.mobileNumberVerified = this.form.mobile_number ?? '';
			this.saveToStorage(this.buildStepValidationPayload());
		},
		buildStepValidationPayload() {
			const form = { ...this.form };

			const { year, month, day } = form.birthdate;
			form.birthdate = `${year}-${month}-${day}`;
			form.is_company = this.isCompany;

			const payload = {
				step: this.currentValidation,
				sponsor: this.sponsorInfo.id,
				personal_information: form,
				identity_id: this.identityId,
			};

			if (this.verificationPhoneToken) {
				payload.verification_phone_token = this.verificationPhoneToken;
			}

			if (this.mobileNumberVerified) {
				payload.mobile_number_verified = this.mobileNumberVerified ?? '';
			}

			return payload;
		},
		createIdentity() {
			const payload = {
				first_name: this.form.first_name,
				last_name: this.form.last_name,
				email: this.form.email,
				mobile_number: this.form.mobile_number,
				identity_id: this.identityId,
				verification_phone_token: this.verificationPhoneToken,
			};
			return this.paymentProcessor.createIdentity(payload).then((response) => {
				this.identityId = response.response.identity_id;
				return response;
			});
		},
		openPhoneVerification() {
			this.openPhoneVerificationModal = true;
		},
		handleChangeMobileNumber() {
			this.$nextTick(() => {
				this.$refs.mobileNumberInput.focus();
			});
		},
		isNINRequired() {
			return this.requiredNinCountries.includes(this.registerCountry);
		},
		clearInvalidFields(data) {
			if (!this.isNINRequired()) {
				data.national_identification_number = '';
			}

			return data;
		},
	},
};
</script>
